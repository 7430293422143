.VisualTransition-enter {
    right: -1100px !important;
    opacity: .6;
}

.VisualTransition-enter-active {
    right: 0 !important;
    opacity: 1;
    transition: all 200ms;
}

.VisualTransition-enter-done {
    right: 0 !important;
    opacity: 1;
}

.VisualTransition-exit {
    right: 0 !important;
    opacity: 1;
}

.VisualTransition-exit-active {
    right: -1100px !important;
    opacity: 0;
    transition: all 200ms;
}

.VisualTransition-exit-done {
    right: -1100px !important;
    opacity: 0;
}