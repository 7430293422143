.NotificationTransition-enter {
    top: 0 !important;
    opacity: .6;
}

.NotificationTransition-enter-active {
    top: 28px !important;
    opacity: 1;
    transition: all 200ms;
}

.NotificationTransition-enter-done {
    top: 28px !important;
    opacity: 1;
}

.NotificationTransition-exit {
    top: 28px !important;
    opacity: 1;
}

.NotificationTransition-exit-active {
    top: -200px !important;
    opacity: 0;
    transition: all 300ms;
}

.NotificationTransition-exit-done {
    top: -200px !important;
    opacity: 0;
}