
.DisplayTransition-enter {
    transform: translate(-50%, -70%) !important;
    opacity: .4;
}
.DisplayTransition-enter-active {
    transform: translate(-50%, -50%) !important;
    opacity: 1;
    transition: all 200ms;
}
.DisplayTransition-enter-done{
    transform: translate(-50%, -50%) !important;
    opacity: 1;
}


.DisplayTransition-exit {
    transform: translate(-50%, -50%) !important;
    opacity: 1;
}

.DisplayTransition-exit-active {
    transform: translate(-50%, -70%) !important;
    opacity: 0;
    transition: all 200ms;
}

.DisplayTransition-exit-done{
    transform: translate(-50%, -70%) !important;
    opacity: 0;
}


