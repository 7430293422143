@import "./base";

/*
html5doctor.com Reset Stylesheet
v1.4.1
2010-03-01
Author: Richard Clark - http://richclarkdesign.com
*/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  vertical-align:baseline;
  background:transparent;
}

article,aside,canvas,details,figcaption,figure,
footer,header,hgroup,menu,nav,section,summary {
  display:block;
}

img{
  pointer-events: none !important;
}

nav ul {
  list-style:none;
}

ul,ol{
  list-style-type: none;
}

blockquote, q {
  quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content:'';
  content:none;
}

table {
  border-collapse:collapse;
  border-spacing:0;
}

input , select , textarea{
  border-radius: 0;
  outline:none;
  box-shadow:none;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}

*, *::after, *::before{
  box-sizing:border-box;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;

}

input:-webkit-autofill{
  background-color: transparent;
}

a{
  color: #222928;
  text-decoration: none;
}

/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
// @font-face {
//   font-family: 'iconfont';  /* Project id 384296 */
//   src: url('//at.alicdn.com/t/c/font_384296_uzesp7yr25.woff2?t=1689041775549') format('woff2'),
//        url('//at.alicdn.com/t/c/font_384296_uzesp7yr25.woff?t=1689041775549') format('woff'),
//        url('//at.alicdn.com/t/c/font_384296_uzesp7yr25.ttf?t=1689041775549') format('truetype');
//   font-display: swap;
// }

@font-face {
  font-family: 'iconfont';  /* Project id 384296 */
  src: url('//at.alicdn.com/t/c/font_384296_spfiibq0kgn.woff2?t=1699442552746') format('woff2'),
       url('//at.alicdn.com/t/c/font_384296_spfiibq0kgn.woff?t=1699442552746') format('woff'),
       url('//at.alicdn.com/t/c/font_384296_spfiibq0kgn.ttf?t=1699442552746') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('https://s3.geeko.ltd/fonts/Roboto-Regular.ttf.gz');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('https://s3.geeko.ltd/fonts/Roboto-Medium.ttf.gz');
  font-display: swap;
}


@font-face {
  font-family: 'Roboto-Bold';
  src: url('https://s3.geeko.ltd/fonts/Roboto-Bold.ttf.gz');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Black';
  src: url('https://s3.geeko.ltd/fonts/Roboto-Black.ttf');
  font-display: swap;
}

#root{
  font-size: actual(14);
  font-family: Roboto, Arial;
  color: var(--primary-color--, #222);
  &.Desktop{
    font-size: 14px;
  }
  &.Me{
    height: 100%;
  }
}

.iconfont{
  font-family:"iconfont" !important;
  font-size:actual(16);
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}





// alert
.NormalAlert{
  width: actual(300);
  background-color: #fff;
  z-index: 21;
  padding: actual(30);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .ButtonBox{
      margin-top: actual(20);
      display: flex;
      justify-content: center;
      align-items: center;
      & > button{
          margin-left: actual(12);
          &:first-child{
              margin-left: 0;
          }
      }
  }

  .Msg{
      font-size: actual(14);
      line-height: actual(20);
      text-align: center;
  }

  .OkButton{
      height: actual(30);
      padding-left: actual(20);
      padding-right: actual(20);
      min-width: actual(110);
      outline: none;
      border: none;
      background-color: #222;
      color: #fff;
      font-family: Roboto-Medium;
      text-transform: uppercase;
  }

  .CancelButton{
    height: actual(30);
    padding-left: actual(20);
    padding-right: actual(20);
    min-width: actual(110);
    border: 1px solid #222;
    outline: none;
    color: #222;
    font-family: Roboto-Medium;
    text-transform: uppercase;
    background-color: transparent;
  }

  .Close{
      position: absolute;
      right: actual(10);
      height: actual(10);
  }

  .MeClose{
    position: absolute;
    top: actual(6);
    right: actual(6);
    font-size: actual(14);
    color: #999;
    font-weight: bold;
    cursor: pointer;
  }
}

.Desktop{
  .NormalAlert{
    width: 360px;
    z-index: 53;
    padding: 30px;

    .ButtonBox{
      margin-top: 20px;
      display: block;
      & > button{
          margin-top: 20px;
          width: 100%;
          display: inline-block;
          margin-left: 0;
          cursor: pointer;

          &:first-child{
              margin-top: 0;
          }
      }
  }
  
    .Msg{
      line-height: normal;
      font-size: 16px;
    }
  
    .CancelButton,.OkButton{
      height: 36px;
      line-height: 36px;
    }
  }
}


// mask
.Mask{
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  position: fixed;
  background-color: rgba(0,0,0,.5);
}


.Toast{
  background-color: rgba(0, 0, 0, 0.7);
  animation: 5s toast forwards;
  padding: 10px 20px;
  border-radius: 20px;
  position: fixed;
  z-index: 52;
  top: calc(50% - 40px);
  left: 50%;
  width: 80%;
  transform: translateX(-50%);
  color: #fff;
  text-align: center;
}

.DesktopToast{
  width: 330px;
}

@keyframes toast {
    0%{
      opacity: 1;
    }

    50%{
      opacity: 1;
    }

    100%{
      opacity: 0;
    }
}


.MaxWith{
  margin-left: 50px;
  margin-right: 50px;
}
